<template>
  <div class="player-modal">
    <Modal
      ref="modal"
      title="Edit Contract"
      is-all-white >
      <div class="columns">
        <div class="column is-half">
          <strong> Club: </strong> <br>
          <strong class="club-name"> {{ newContract.club_name }} </strong>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field label="Date Signed"
          :type="submitFlag && invalidSignDate ? 'has-text-danger' : ''">
            <DatePickerString
              append-to-body
              :min-date="minDate"
              v-model="properSignDate"
              icon-right="calendar"
              placeholder="MM/DD/YYYY"
              editable/>
          </b-field>
          <div v-if="submitFlag && !properSignDate">
            <span class="help is-danger">
              This is a required field
            </span>
          </div>
          <div v-else-if="submitFlag && invalidSignDate">
            <span class="help is-danger">
              New sign date cannot be after renew date
            </span>
          </div>
        </div>
        <div class="column is-half">
          <b-field label="Renewal Date"
          :type="submitFlag && invalidRenewDate ? 'has-text-danger' : ''">
            <DatePickerString
              append-to-body
              :min-date="minDate"
              v-model="properRenewDate"
              icon-right="calendar"
              placeholder="MM/DD/YYYY"
              editable/>
          </b-field>
          <div v-if="submitFlag && !properRenewDate">
            <span class="help is-danger">
              This is a required field
            </span>
          </div>
          <div v-else-if="submitFlag && invalidRenewDate">
            <span class="help is-danger">
              Renew date should be after sign date
            </span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field label="Contract Rec Players"
          :type="submitFlag && invalidRecCap ? 'has-text-danger' : ''">
            <b-input type='text'
              :value="newContract.rec_player_cap !== null ? newContract.rec_player_cap.toLocaleString() : ''"
              @input="setFromLocaleString('rec_player_cap', $event)"/>
          </b-field>
          <div v-if="submitFlag && newContract.rec_player_cap == null">
            <span class="help is-danger">
              Player cap value is required
            </span>
          </div>
          <div v-else-if="submitFlag && invalidRecCap">
            <span class="help is-danger">
              Player cap must be greater than or equal to zero
            </span>
          </div>
        </div>
        <div class="column is-half">
          <b-field label="Contract Comp Players"
          :type="submitFlag && invalidCompCap ? 'has-text-danger' : ''">
            <b-input type='text'
              :value="newContract.competitive_player_cap !== null ? newContract.competitive_player_cap.toLocaleString() : ''"
              @input="setFromLocaleString('competitive_player_cap', $event)"/>
          </b-field>
          <div v-if="submitFlag && newContract.competitive_player_cap == null">
            <span class="help is-danger">
              Player cap value is required
            </span>
          </div>
          <div v-else-if="submitFlag && invalidCompCap">
            <span class="help is-danger">
              Player cap must be greater than or equal to zero
            </span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field label="Sales Rep">
            <b-select v-model.number="club.config.sales_contact_user_id">
              <option v-for="[id, name] in salesUsers" :key="id" :value="id"> {{ name }} </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <template slot="footer">
        <b-button type="is-primary" @click="saveChanges()">Edit Contract</b-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import moment from 'moment';
import api from '@/http-playmetrics';
import _ from 'lodash';
import DatePickerString from '@/components/common/form-elements/DatePickerString';

export default {

  components: {
    Modal,
    DatePickerString,
  },
  data() {
    return {
      club: { config: { sales_contact_user_id: 0 } },
      newContract: {
        club_id: '',
        name: '',
        size: '',
        sport: '',
        renew_date: null,
        sign_date: null,
        rec_player_cap: 0,
        rec_player_actual: 0,
        competitive_player_cap: 0,
        competitive_player_actual: 0,
        sales_contact_user_name: '',
        isArchived: false,
      },
      properRenewDate: null,
      properSignDate: null,
      properDate: null,
      submitFlag: false,
    };
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    minDate() {
      return new Date('01-10-2018');
    },
    maxDate() {
      const dt = new Date();
      dt.setFullYear(dt.getFullYear() + 5);
      return dt.toLocaleDateString('en-US');
    },
    formatDate() {
      return moment(this.newContract.renew_date).format('YYYY-MM-DD');
    },
    renewalProps() {
      return {
        club_id: this.newContract.club_id,
        sign_date: this.properSignDate ? moment(this.properSignDate).format('YYYY-MM-DD') : null,
        renew_date: this.properRenewDate ? moment(this.properRenewDate).format('YYYY-MM-DD') : null,
        rec_player_cap: parseInt(this.newContract.rec_player_cap, 10),
        competitive_player_cap: parseInt(this.newContract.competitive_player_cap, 10),
      };
    },
    invalidFields() {
      return (this.invalidCompCap || this.invalidRecCap || this.invalidRenewDate || this.invalidSignDate);
    },
    invalidRecCap() {
      if (typeof this.newContract.rec_player_cap !== 'number') return true;
      else if (this.newContract.rec_player_cap <= -1) return true;
      else return false;
    },
    invalidCompCap() {
      if (typeof this.newContract.competitive_player_cap !== 'number') return true;
      else if (this.newContract.competitive_player_cap <= -1) return true;
      else return false;
    },
    invalidRenewDate() {
      return !this.properRenewDate || (new Date(this.properRenewDate) < new Date(this.properSignDate));
    },
    invalidSignDate() {
      return !this.properSignDate || (new Date(this.properSignDate) > new Date(this.properRenewDate));
    },
  },
  methods: {
    clubsByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    setFromLocaleString(field, value) {
      if (value !== '') {
        this.newContract[field] = parseInt(value.replaceAll(',', ''), 10) || 0;
      } else this.newContract[field] = value;
    },
    saveChanges() {
      if (this.invalidFields) {
        this.submitFlag = true;
      } else {
        const contractUrl = `/cst/club_contracts/${this.newContract.id}`;
        api().post(contractUrl, this.renewalProps)
          .then(() => this.updateClubSalesUser());
      }
    },
    updateClubSalesUser() {
      const clubUrl = `/cst/clubs/${this.club.id}`;
      api().post(clubUrl, this.club)
        .then(() => this.finishUpdate());
    },
    finishUpdate() {
      this.$store.commit('setNotification', { toast: true, success: true, message: 'Contract updated' });
      this.dismissModal({ refreshData: true });
    },
    showModal(con) {
      if (con) {
        this.club = _.cloneDeep(this.clubsByID(con.club_id));
        this.newContract = _.cloneDeep(con);
        this.properRenewDate = _.clone(con.renew_date);
        this.properSignDate = _.clone(con.sign_date);
      } else {
        this.newContract = {
          club_id: null,
          name: null,
          size: null,
          sport: null,
          renew_date: null,
          sign_date: null,
          rec_player_cap: null,
          rec_player_actual: null,
          competitive_player_cap: null,
          competitive_player_actual: null,
          sales_contact_user_name: null,
          isArchived: null,
        };
      }
      this.$refs.modal.showModal();
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissed', options);
      this.$refs.modal.dismissModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.club-name
  font-size: 18px
.is-italic
  display: flex
  font-style: italic
  justify-content: center
</style>
