<template>
  <Spinner v-if="loading"/>
  <div v-else class="clubs">
    <b-tabs
      v-model="activeTab"
      @input="tabHandler($event)"
      :expanded="$store.getters.isMobile"
      :class="{'tabs-mobile': $store.getters.isMobile}"
      type="is-toggle"
      class="b-tabs-no-content">
      <b-tab-item label="All" />
      <b-tab-item label="Needs Attention" />
    </b-tabs>
    <TablePaginated
      name="Clubs"
      :data="hasStateSelected ? clubsByState : filteredTableRows"
      :filters-active="hasActiveFilters || showTestClubs"
      searchable
      hoverable
      :row-class="() => 'clickable'"
      @queryUpdated="searchQuery = $event"
      @select="viewClub($event)">
      <template slot="header-left">
          <span class="label">{{filteredTableRows.length}} {{filteredTableRows.length === 1 ? 'club' : 'clubs'}} found</span>
      </template>
      <template slot-scope="props">
        <b-table-column
          header-class="is-unselectable"
          width="2rem"
          field="alert">
          <CustomerHealthModalPopover v-if="props.row.health_status" :customerHealth="props.row"/>
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="ID"
          field="id"
          sortable>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Club Name"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Contract Name"
          field="contract_name"
          sortable>
          {{ props.row.contract_name }}
        </b-table-column>
        <b-table-column :visible="activeTab === 1"
          :header-selectable="false"
          label="Legal Entity Name"
          field="legal_entity_name"
          sortable>
          {{ props.row.legal_entity_name }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Size"
          field="size"
          sortable>
          {{ props.row.size | capitalize }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="State"
          field="state"
          sortable>
          {{ props.row.state }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Sport"
          field="sport"
          sortable>
          {{ props.row.sport | capitalize }}
        </b-table-column>
        <b-table-column centered
          label="Actions"
          field="actions"
          v-if="canAccess(['product', 'success', 'operations', 'sales'])">
          <div class="clubs-table__actions">
            <a
              class="button is-text"
              @click.stop="editClub(props.row.id)"
            >
              <fa-icon :icon="['fas', 'pencil']" />
            </a>
            <b-dropdown
              ref="dropdown"
              aria-role="list"
              position="is-bottom-left"
              @click.native.stop>
              <a class="clubs-table__actions--link"
                 slot="trigger">
                <div class="is-size-6"><fa-icon :icon="['far', 'ellipsis-h']" /></div>
              </a>
              <b-dropdown-item
                aria-role="listitem"
                @click=" updateOnboardingHealth(clubInternalsByID[props.row.id])">
                <div class="columns is-mobile">
                  <div class="column has-text-primary">
                    <fa-icon :icon="['fas', 'pencil']" />
                    Update Customer Health
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <ClubsFilter :filters-active="hasActiveFilters"
      :states="states"
      @showTestClubs="showTestClubs = $event"
      @input="selectState($event)"/>
    <UpdateOnboardingHealth @dismissed="fetchInternalClubs()" ref="onboardingHealth"/>
    <AddEditClub @dismissed="addEditClubDismissed" ref="addEditClub"/>
  </div>
</template>

<script>
import _ from 'lodash';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import CustomerHealthModalPopover from '@/components/common/CustomerHealthModalPopover';
import api from '@/http-playmetrics';
import AddEditClub from '@/components/clubs/AddEditClub';
import UpdateOnboardingHealth from '@/components/onboarding/plans/UpdateOnboardingHealth';
import Utils from '@/models/Utils';
import ClubsFilter from './ClubsFilter';

// REMOVE AFTER NORMALIZING IN DB
const normalizeOnboardingType = (obt) => {
  if (obt === 'Full Service') return 'full';
  if (obt === 'Mid Service') return 'mid';
  if (obt === 'Self Service') return 'self';
  return obt;
};

export default {
  components: { Spinner,
    TablePaginated,
    UpdateOnboardingHealth,
    ClubsFilter,
    AddEditClub,
    CustomerHealthModalPopover,
  },
  props: {
    clubs: { type: Array, default: () => [] },
  },
  data() {
    return {
      hasPopulatedLegalEntityDetails: false,
      invalidContractNames: {},
      legalEntityDetailsByID: {},
      clubInternalsByID: null,
      activeTab: 0,
      merchantAccounts: [],
      searchQuery: '',
      plans: [],
      pandadocs: [],
      selectedStates: [],
      clubInternals: [],
      showTestClubs: false,
    };
  },
  computed: {
    loading() {
      return !(this.clubs && this.clubInternalsByID);
    },
    EXPORT_CLUBS() {
      return { text: 'Export Clubs', icon: 'sign-in', icon_transform: 'rotate-90', classes: 'is-outlined' };
    },
    clubsByID() {
      return _.keyBy(this.clubs, 'id');
    },
    showingClubs() {
      return this.clubs.filter(club => !club.is_deleted && (this.showTestClubs || !club.is_test_club));
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    states() {
      const st = [];
      this.tableRows.forEach((c) => {
        if (c.state !== '-' && !st.includes(c.state)) st.push(c.state);
      });
      return st.sort((state1, state2) => (state1 < state2 ? -1 : 1));
    },
    tableRows() {
      return this.showingClubs.map(club => ({
        id: club.id,
        name: club.name,
        contract_name: this.clubInternalsByID[club.id]?.contract_name ?? '-',
        legal_entity_name: this.clubInternalsByID[club.id]?.legal_entity_name ?? '-',
        size: club.size ? club.size : '-',
        state: club.config.state ? club.config.state : '-',
        sport: club.sports.length ? this.clubSports(club.sports) : '-',
        rec_player_cap: club.contract?.rec_player_cap || 0,
        competitive_player_cap: club.contract?.competitive_player_cap || 0,
        health_status: this.clubInternalsByID[club.id]?.health_status || '',
        health_notes: this.clubInternalsByID[club.id]?.health_notes || '',
        health_updated_at: this.clubInternalsByID[club.id]?.health_updated_at || '',
        health_updated_by_user_id: this.clubInternalsByID[club.id]?.health_updated_by_user_id || 0,
        onboarding_type: normalizeOnboardingType(club.config.onboarding_type),
        onboarding_status: club.config.onboarding_status,
        enable_program_administration: club.config.enable_program_administration,
        enable_digital_documents: club.config.enable_digital_documents,
        enable_website_builder: club.config.enable_website_builder,
      }));
    },
    filteredTableRows() {
      return this.tableRows.filter(club => (this.matchesClubFilter(club) && this.matchesQuery(club)
        && (this.activeTab === 1 ? this.invalidContractNames[club.id] : true)));
    },
    clubsByState() {
      return this.filteredTableRows.filter(club => this.selectedStates.includes(club.state));
    },
    ADD_CLUB() {
      return 'Add Club';
    },
    adminUsersByID() {
      return _.keyBy(this.$store.getters.users, 'id');
    },
    hasActiveFilters() {
      return !_.isEmpty(this.activeFilters) || this.hasStateSelected;
    },
    hasStateSelected() {
      return this.states.some(st => this.selectedStates.includes(st));
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('club');
    },
  },
  mounted() {
    this.fetchInternalClubs();
  },
  methods: {
    tabHandler(event) {
      if (event === 1 && !this.hasPopulatedLegalEntityDetails) {
        this.hasPopulatedLegalEntityDetails = true;
        this.fetchInternalClubs('?populate=legal_entity_details');
      }
    },
    canAccess(depts) {
      return this.$store.getters.canAccess(depts);
    },
    getCustomerHealthFields(id, name) {
      const ret = this.clubInternalsByID[id];
      ret.club_name = name;
      return ret;
    },
    createdByUser(id) {
      const user = this.$store.getters.users.find(usr => usr.id === id);
      return user ? `${user?.first_name} ${user?.last_name}` : '';
    },
    updateOnboardingHealth(club) {
      this.$refs.onboardingHealth.showModal(club);
    },
    selectState(st) {
      if (st === 'clear') {
        this.selectedStates = [];
      } else if (this.selectedStates.includes(st)) {
        this.selectedStates = _.without(this.selectedStates, st);
      } else {
        this.selectedStates = _.concat(this.selectedStates, st);
      }
    },
    fetchInternalClubs(param = '') {
      this.clubInternalsByID = null;
      api().post(`/cst/club_internals/search${param}`).then((res) => {
        const clubInternalsByID = {};
        const invalidContractNames = {};
        res.data.forEach((club) => {
          clubInternalsByID[club.id] = club;
          if (Object.hasOwn(club, 'legal_entity_name') && club.contract_name !== club.legal_entity_name) {
            invalidContractNames[club.id] = club;
          }
        });
        this.clubInternalsByID = clubInternalsByID;
        this.invalidContractNames = invalidContractNames;
      });
    },
    matchesQuery(club) {
      return (
        (club.name &&
          club.name.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (club.id && club.id.toString().includes(this.searchQuery.toLowerCase()))
      );
    },
    matchesClubFilter(club) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.activeFilters).every(key => this.activeFilters[key].matches(club));
    },
    editClub(id) {
      const club = this.clubsByID[id];
      this.$refs.addEditClub.show(club);
    },
    clubSports(sports) {
      // juts show 'main' sport
      if (sports) {
        return sports[0].sport;
      }
      return '-';
    },
    clubSalesContactName(id) {
      return Utils.getUserNameById(this.salesUsers, id, '-');
    },
    addEditClubDismissed(options) {
      if (options.refreshData) {
        // reload in store
        this.hasPopulatedLegalEntityDetails = false;
        this.activeTab = 0;
        this.$store.dispatch('searchClubs').then(() => this.$emit('load-clubs'));
      }
    },
    classForStatus(status) {
      if (status.toLowerCase() === 'inactive') return 'is-danger';
      if (status.toLowerCase() === 'onboarding') return 'is-warning';
      if (status.toLowerCase() === 'active') return 'is-success';
      return '';
    },
    viewClub(club) {
      this.$router.push({ name: 'ClubViewSummary', params: { club: club.id, fromTab: 'clubs' } });
    },
  },
};
</script>

<style lang="sass" scoped>
.badge.tag
  font-weight: $weight-semibold
  font-size: $text-size-8
  font-family: Montserrat, 'sans-serif'
.clubs-table
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &--link
      color: inherit
</style>
