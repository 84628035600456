<template>
  <div>
    <DeviceDetector />
    <MobileDisabled v-if="$store.getters.isTouch" name="PlayMetrics Customer Success tracker" />
    <div v-else id="default_layout">
      <template v-if="!loading">
        <div class="main-wrapper">
          <SideNav />
          <div class="main has-sidenav">
            <section
              class="layout__page">
              <main>
                <router-view
                  class="full-height"/>
              </main>
            </section>
          </div>
        </div>
      </template>
      <Spinner v-if="loading"/>
    </div>
  </div>
</template>

<script>
import MobileDisabled from 'jseminor/components/MobileDisabled';
import Spinner from '@/components/common/Spinner';
import SideNav from '@/layouts/default/SideNav';
import DeviceDetector from '@/components/app/DeviceDetector';

export default {
  components: {
    MobileDisabled,
    DeviceDetector,
    SideNav,
    Spinner,
  },
  computed: {
    loading() {
      return !this.$store.getters.user;
    },
  },
};
</script>
